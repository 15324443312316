import React from 'react';
import { ModalContainer } from './ModalContainer';
import { Txt } from '../../StyleGuide/Components/Txt';
import { useDispatch } from 'react-redux';
import { Button } from '../../StyleGuide/Components/Button';
import { offModal } from '../../../redux/actions/modalAction';
import { Row } from '../../../styles/Layout/Row';
import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon';
import { Col } from '../../../styles/Layout/Col';
import {Img} from "../../StyleGuide/Components/Img";
import {styled} from "@stitches/react";

export const NoticeModal = ({ width = 730 }) => {
  const { t } = useTranslation('modal');
  const dispatch = useDispatch();

  function dayOff() {
    localStorage.setItem('isNoShow', 'true');
    dispatch({ type: offModal });
  }

  return (
    <ModalContainer
      css={{
        maxWidth: width,
        width: '100%',
        height: 'auto',
        '@mb': {
          height: '100%',
          padding: 20,
        },
      }}
    >
        <Row css={{
            width: '100%',
            marginBottom: 50,
        }}>
            <ExcoinLogo
                src={'/images/excoinz-logo.svg'}
                css={{}}
            />
        </Row>

      <Row
        css={{
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Txt e1 css={{ textAlign: 'left', fontSize: 20 }}>
          {t('notice')}
        </Txt>

      </Row>
        <Icon
            onClick={() => {
                dispatch({ type: offModal });
            }}
            css={{
                width: 16,
                height: 16,
                cursor: 'pointer',
                position:'absolute',
                top: 30,
                right: 30,
            }}
            src={'/images/icon-close.svg'}
        />
      <Col
        css={{
          width: '100%',
          marginTop: 27,
          marginBottom: 30,
          gap: 10,
          '@mb': { justifyContent: 'flex-start' },
        }}
      >
        <Txt css={{ textAlign: 'left', marginBottom: 20, whiteSpace:"pre-line" }} h3>
          {'On behalf of the Excoinz team, we extend our sincere gratitude to all our valued customers'}
        </Txt>
        <Txt css={{ textAlign: 'left', whiteSpace:"pre-line"  }} b2>
          {'To ensure a smooth transition and optimal performance for the upcoming launch of ExPay, we have temporarily suspended Excoinz services for a thorough system inspection.\n'}
        </Txt>

        <Txt css={{ textAlign: 'left', whiteSpace:"pre-line"  }} b2>
          {'We anticipate resuming full Excoinz services in mid-February and will provide you with the exact date via email or through a notification on our official website.'}
        </Txt>

        <Txt css={{ textAlign: 'left', whiteSpace:"pre-line"  }} b2>
          {'Thank you for your understanding and continued support.'}
        </Txt>
      </Col>
      {/*<Button*/}
      {/*  lg*/}
      {/*  onClick={() => {*/}
      {/*    dayOff();*/}
      {/*  }}*/}
      {/*  css={{*/}
      {/*    '@mb': {*/}
      {/*      width: '100%',*/}
      {/*      maxWidth: '100%',*/}
      {/*    },*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {t('no_show_today')}*/}
      {/*</Button>*/}
    </ModalContainer>
  );
};

const ExcoinLogo = styled(Icon, {

});
